.hedefler {
  &__wrapper {
    display: flex;
    justify-content: space-between;

    ul {
      li {
        padding: 0.25rem 0;
      }
    }
  }

  &__section {
    background-color: white;
    border-radius: 0.5rem;
    padding: 1rem;
    height: 100%;
  }

  &__caption {
    font-weight: 600;
    padding-bottom: 1rem;
  }
}
