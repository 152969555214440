@mixin box_shadow_onhover($bg) {
  background: $bg;
  &:hover {
    box-shadow: 0 0 20px 0 $bg;
    cursor: pointer;
  }
  > a {
    display: block;
  }
}
