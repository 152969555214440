.overlay {
  position: fixed;
  z-index: 999;
  overflow-y: scroll;
  padding-top: 100px;

  &-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 3rem;
    line-height: 1.5rem;
    cursor: pointer;
    color: white;
  }

  &-fullpage {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
  }

  &-content {
    position: relative;
    border: 1px solid white;
    background-color: rgba(33, 50, 86, 1);
    margin: auto 1rem;

    ul, p,
    table,
    h3 {
      margin-bottom: 1rem;
      color: white;
    }
    
    li {
      color: white;
    }
    
    table {
      border: none;
      th {
        background-color: #cccccc25;
      }
      td {
        padding: .2rem;
        text-align: left;
      }
    }

    &__centered {
      display: flex;
flex-direction: column;
      text-align: center;
      padding: 3rem 1rem;
    }

    &__left {
      padding: 3rem 1rem;
    }
  }

  &-logo-wrapper {
    position: absolute;
    padding: 0 2rem;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    background-color: rgba(33, 50, 86, 1);
    display: inline-block;
  }
}
