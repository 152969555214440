.percent-chart {
  width: 8em;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 3.5;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart .circle {
  stroke: #0067f4;
}

.percentage {
  fill: #0067f4;
  font-family: inherit;
  font-size: 0.5em;
  text-anchor: middle;
  position: absolute;
  left: 0;
  top: 50px;
}

.ska-targets {
  &__4 {
    @include box_shadow_onhover(#c72030);
  }

  &__8 {
    @include box_shadow_onhover(#a21c43);
  }

  &__10 {
    @include box_shadow_onhover(#de1867);
  }

  &__17 {
    @include box_shadow_onhover(#14496b);
  }
}
