.elemanNet {
  &__container {
    width: 100%;
    text-align: center;
    margin: 0 auto;

    iframe {
      width: 100%;
      height: 1600px;
      border: none;
    }
  }
    &__referto {
      margin: 20px 0;font-size:8px;
      &-link {
        text-decoration:none;color:inherit !important;
      }
      &-img {
        vertical-align:middle;width:120px
      }
    }
}