.u2c__home {
  &--video-intro {
    box-shadow: 0 0 40px 0 #11100f;
    max-width: 100%;
    background-color: #0067f4;

    .vjs-poster {
      background-color: #0067f4;
    }
  }
}
