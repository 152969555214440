@import "./mixins";
@import "./global";
@import "./home";
@import "./ska";
@import "./footer";
@import "./mobile-video";
@import "./team";
@import "./features";
@import "./hedefler";
@import "./overlay";
@import "./eleman.net";
@import "./animations";
@import "./error-pages";
html {
  a[type="button"] {
    -webkit-appearance: none;
  }
}
.testimonial {
  &-text {
    height: 500px;
    overflow-y: scroll;
    position: relative;
  }
  &-more-text {
    position: absolute;
    display: block;
    height: 100px;
    width: 100%;
    top: 440px;
    background-image: linear-gradient(0deg, #ffffff, #ffffff01);
    z-index: 1;
  }
}
