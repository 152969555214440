.errorPage {
    color: #c1c1c1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25vh;

    &__content {
        * {
            color: #c1c1c1;
        }
        h1 {
            font-size: 3rem;
            line-height: 1.5;
            text-align: center;
        }

        p {
            font-size: 1.5rem;
            line-height: 2;
            text-align: center;
        }
    }
}