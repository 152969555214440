.team-style-eleven {
  .team-image {
    aspect-ratio: 700/1024;
  }
  .sub-title {
    padding: 0 5px;
  }
  .sub-title-more {
    display: none;
  }
  &:hover {
    .sub-title-more {
      display: block;
      color: white;
      padding: 0 10px;
    }
  }
}
